<template>
    <div class="contain-box full-height">
        <CardBox>
            <template #tool-title>
                <div class="tab-bar">
                    <template v-for="item in tabMap">
                        <span
                            :class="{ active: route.path === item.url }"
                            :key="item.title"
                            v-if="store.getters.hasViewPermission(item.url)"
                            @click="router.push(item.url)">
                            {{ $t(item.title) }}
                        </span>
                    </template>
                </div>
            </template>
            <template #tool-bar>
                <Space :size="20">
                    <Search @search="search" :resetVal="resetVal" :placeholder="t('user.team')" :width="210" />
                    <Button
                        class="btn-fill"
                        type="primary"
                        size="large"
                        @click=";(showResourceModal = true), (operation = 1)"
                        v-if="pageConf.currentRoute === 'team'">
                        {{ t('resource.addSrc') }}
                    </Button>
                </Space>
            </template>
            <template #contain>
                <PublicResourceTable :conf="pageConf" v-if="pageConf.currentRoute === 'public'" @modifyResource="modifyResource" @deleteData="changeOption" />
                <TeamResourceTable :conf="pageConf" v-if="pageConf.currentRoute === 'team'" @modifyResource="modifyResource" @deleteData="changeOption" />
            </template>
        </CardBox>
        <AddResourceModal
            :operation="operation"
            :dataType="pageConf.currentRoute"
            :data="resourceItem"
            :showModal="showResourceModal"
            @closeModal="showResourceModal = false"
            @addData="changeOption"
            @updateData="getData" />
    </div>
</template>

<script>
import CardBox from '@/components/CardBox.vue'
import Search from '@/components/Search.vue'
import AddResourceModal from '@/components/AddResourceModal.vue'
import PublicResourceTable from './PublicResourceTable.vue'
import TeamResourceTable from './TeamResourceTable.vue'
import useWindowResize from '@/hooks/useWindowResize'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { defineComponent, reactive, ref, inject, watch } from 'vue'
export default defineComponent({
    components: {
        PublicResourceTable,
        TeamResourceTable,
        CardBox,
        Search,
        AddResourceModal
    },
    setup() {
        const t = inject('$t')
        const http = inject('$http')
        const winData = useWindowResize()
        const route = useRoute()
        const router = useRouter()
        const store = useStore()
        const operation = ref(1) // 1、添加；0、修改
        const resourceItem = ref(null)
        const showResourceModal = ref(false)
        const resetVal = ref(true)
        const tabMap = store.state.navMap[4].child
        watch(
            () => winData.height,
            (val) => {
                pageConf.height = val - 360
            }
        )
        const pageConf = reactive({
            teamName: '',
            tableData: [],
            currentRoute: route.params.type,
            showTotal: true,
            showPageSize: true,
            hideOnSinglePage: false,
            pageSizeOptions: [30, 60, 100],
            current: 1,
            pageSize: 30,
            total: 0,
            height: 570,
            onChange: (data) => {
                pageConf.current = data
                getData()
            },
            onPageSizeChange: (data) => {
                pageConf.current = 1
                pageConf.pageSize = data
                getData()
            }
        })
        const search = (val) => {
            pageConf.current = 1
            pageConf.content = val
            getData()
        }
        const changeOption = () => {
            pageConf.current = 1
            getData()
        }
        const getData = () => {
            const params = {
                page: pageConf.current - 1,
                size: pageConf.pageSize,
                content: pageConf.content,
                publicFlag: pageConf.currentRoute === 'public'
            }
            http.account.getResource(params).then(({ data }) => {
                pageConf.tableData = data.data
                pageConf.total = data.total
                document.querySelector('.arco-table-body').scrollTop = 0
                showResourceModal.value = false
            })
        }
        watch(
            () => route.params,
            (val) => {
                if (val.type) {
                    pageConf.currentRoute = val.type
                    pageConf.content = ''
                    pageConf.tableData = []
                    pageConf.current = 1
                    pageConf.pageSize = 30
                    resetVal.value = !resetVal.value
                    getData()
                }
            },
            { immediate: true }
        )
        const modifyResource = (data) => {
            resourceItem.value = data
            operation.value = 0
            showResourceModal.value = true
        }

        return {
            showResourceModal,
            resourceItem,
            pageConf,
            operation,
            resetVal,
            store,
            router,
            route,
            t,
            search,
            getData,
            modifyResource,
            changeOption,
            tabMap
        }
    }
})
</script>

<style lang="less" scoped>
.contain-box {
    .user-table {
        padding: 0 60px;
        margin-top: 30px;
    }
}
</style>
