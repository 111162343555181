<template>
    <Modal width="500px" v-model:visible="visible" @close="handleClose" :mask-closable="false">
        <template #title>
            <p class="form-title" v-if="dataType === 'public'">{{ t('resource.confPublicSrc') }}</p>
            <p class="form-title" v-else-if="operation">{{ t('resource.addSrc') }}</p>
            <p class="form-title" v-else>{{ t('resource.confSrc') }}</p>
        </template>
        <div>
            <Form ref="formDom" :model="formData" :rules="formRule" layout="vertical" autocomplete="off">
                <FormItem :hide-label="true" row-class="form-item-require" field="id">
                    <span class="form-item-title">{{ t('user.team') }} </span>
                    <Input v-model.trim="formData.teamName" style="width: 180px" v-if="operation === 0" disabled />
                    <Select v-else v-model="formData.id" :placeholder="t('holder.selectHolder')" :style="{ width: '180px' }" @change="validateField('id')">
                        <Option :value="item.id" v-for="item in teamList" :key="item.id">{{ item.teamName }}</Option>
                    </Select>
                </FormItem>
                <FormItem :hide-label="true" row-class="form-item-require" field="ip" v-if="dataType === 'team'">
                    <span class="form-item-title">{{ t('resource.ip') }} </span>
                    <Input
                        v-model.trim="formData.ip"
                        spellcheck="false"
                        style="width: 180px"
                        :placeholder="t('holder.enterHolder')"
                        @blur="validateField('ip')" />
                </FormItem>
                <FormItem :hide-label="true" row-class="form-item-require" field="specification" v-if="dataType === 'team'">
                    <span class="form-item-title">{{ t('resource.specification') }} </span>
                    <Select
                        v-model="formData.specification"
                        :placeholder="t('holder.selectHolder')"
                        :style="{ width: '180px' }"
                        @change="validateField('specification')">
                        <Option value="1">CPU-1 RAM-2GB</Option>
                        <Option value="2">CPU-2 RAM-4GB</Option>
                        <Option value="4">CPU-4 RAM-8GB</Option>
                        <Option value="8">CPU-8 RAM-16GB</Option>
                        <Option value="16">CPU-16 RAM-32GB</Option>
                    </Select>
                </FormItem>
                <FormItem :hide-label="true" row-class="form-item-require" field="storage" v-if="dataType === 'team'">
                    <span class="form-item-title">{{ t('resource.storage') }} </span>
                    <Select v-model="formData.storage" :placeholder="t('holder.selectHolder')" :style="{ width: '180px' }" @change="validateField('storage')">
                        <Option value="20">20GB</Option>
                        <Option value="40">40GB</Option>
                        <Option value="60">60GB</Option>
                        <Option value="80">80GB</Option>
                        <Option value="100">100GB</Option>
                    </Select>
                </FormItem>
                <FormItem :hide-label="true" row-class="form-item-require" field="maxCount">
                    <span class="form-item-title">{{ t('resource.spaceNum') }} </span>
                    <InputNumber
                        v-model="formData.maxCount"
                        :controls="false"
                        spellcheck="false"
                        style="width: 180px"
                        :placeholder="t('holder.enterHolder')"
                        @blur="validateField('maxCount')" />
                </FormItem>
                <FormItem :hide-label="true" row-class="form-item-require" field="runningMaxCount">
                    <span class="form-item-title">{{ t('resource.runningSpace') }} </span>
                    <InputNumber
                        v-model="formData.runningMaxCount"
                        :controls="false"
                        spellcheck="false"
                        style="width: 180px"
                        :placeholder="t('holder.enterHolder')"
                        @blur="validateField('runningMaxCount')" />
                </FormItem>
                <FormItem :hide-label="true" row-class="form-item-require" field="expireTime">
                    <span class="form-item-title">{{ t('resource.expireTime') }} </span>
                    <DatePicker style="width: 180px" v-model="formData.expireTime" @change="validateField('expireTime')" />
                </FormItem>
            </Form>
        </div>
        <template #footer>
            <Button class="btn-fill" type="primary" html-type="submit" @click="handleSubmit" :loading="loading">
                {{ t('ok') }}
            </Button>
        </template>
    </Modal>
</template>

<script>
import { defineComponent, ref, watch, reactive, inject } from 'vue'
import { Form } from '@arco-design/web-vue'
// import * as reg from '@/utils/formReg'
export default defineComponent({
    props: {
        dataType: {
            type: String
        },
        showModal: {
            type: Boolean,
            default: false
        },
        operation: {
            type: Number,
            default: 1 // 1、添加；0、修改
        },
        data: {
            type: Object
        }
    },
    setup(props, ctx) {
        const t = inject('$t')
        const http = inject('$http')
        const formDom = ref(null || Form)
        const loading = ref(false)
        const msg = inject('$message')
        const moment = inject('$moment')
        const teamList = ref(null)
        const formData = reactive({
            id: '',
            ip: '',
            storage: '',
            specification: '',
            maxCount: 1,
            runningMaxCount: 1,
            expireTime: '',
            teamName: ''
        })
        const formRule = {
            id: [{ required: true, message: t('tip.emptyTip', { key: t('user.team') }) }],
            ip: [
                { required: true, message: t('tip.emptyTip', { key: t('resource.ip') }) },
                {
                    required: true,
                    match: /^((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.){3}(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])$/,
                    message: t('tip.ipTip')
                }
            ],
            storage: [{ required: true, message: t('tip.emptyTip', { key: t('resource.storage') }) }],
            specification: [{ required: true, message: t('tip.emptyTip', { key: t('resource.specification') }) }],
            maxCount: [{ required: true, match: /^([1-9]\d{0,2})$/, message: t('tip.numberTip', { text: '1~999' }) }],
            runningMaxCount: [{ required: true, match: /^([1-9]\d{0,2})$/, message: t('tip.numberTip', { text: '1~999' }) }],
            expireTime: [{ required: true, message: t('tip.emptyTip', { key: t('resource.expireTime') }) }]
        }
        const visible = ref(props.showModal)
        watch(
            () => props.showModal,
            (val) => {
                if (val) getTeamList()
                if (props.operation) {
                    formDom.value.resetFields()
                } else if (props.dataType === 'team') {
                    formData.id = props.data.id
                    formData.teamName = props.data.teamName
                    formData.ip = props.data.ip
                    formData.storage = props.data.storage
                    formData.specification = props.data.specification
                    formData.maxCount = props.data.maxCount
                    formData.runningMaxCount = props.data.runningMaxCount
                    formData.expireTime = moment(props.data.expireTime).format('YYYY-MM-DD')
                } else if (props.dataType === 'public') {
                    formData.id = props.data.id
                    formData.teamName = props.data.teamName
                    formData.maxCount = props.data.maxCount
                    formData.runningMaxCount = props.data.runningMaxCount
                    formData.expireTime = moment(props.data.expireTime).format('YYYY-MM-DD')
                    formData.ip = ''
                    formData.storage = ''
                    formData.specification = ''
                }
                visible.value = val
            }
        )
        const handleClose = () => {
            loading.value = false
            formDom.value.resetFields()
            ctx.emit('closeModal')
        }
        const getTeamList = () => {
            http.account.getTeamOption().then(({ data }) => {
                teamList.value = data
            })
        }
        const handleSubmit = () => {
            formDom.value.validate((errors) => {
                if (!errors) {
                    loading.value = true
                    let api = null
                    if (props.operation) {
                        const { ...params } = formData
                        params.teamId = params.id
                        delete params.id
                        delete params.teamName
                        api = http.account.addResource(params)
                    } else if (props.dataType === 'team') {
                        const { ...params } = formData
                        delete params.teamName
                        api = http.account.updateResource(params)
                    } else {
                        const { ...params } = formData
                        delete params.teamName
                        delete params.ip
                        delete params.storage
                        delete params.specification
                        api = http.account.updateTrialResource(params)
                    }

                    api.then(() => {
                        loading.value = false
                        if (props.operation) {
                            msg.info(t('addSucc'))
                            ctx.emit('addData')
                        } else {
                            msg.info(t('modifySucc'))
                            ctx.emit('updateData')
                        }
                    }).catch(() => {
                        loading.value = false
                    })
                }
            })
        }
        const validateField = (field) => {
            formDom.value.validateField(field)
        }

        return {
            visible,
            formData,
            formRule,
            formDom,
            loading,
            teamList,
            handleSubmit,
            validateField,
            handleClose,
            t
        }
    }
})
</script>

<style lang="less" scoped>
.form-item-require {
    :deep(.arco-form-item-message) {
        width: 100%;
        text-align: right;
    }
}
.form-title {
    font-size: 18px;
    font-weight: 700;
    color: #666666;
}
.form-item-title {
    font-size: 14px;
    color: #4e5969;
    width: 200px;
}
.arco-form-item-label-col {
    display: none;
}
.form-item-view {
    padding-left: 12px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    span:first-child {
        // width: 40%;
        color: #4e5969;
        // font-weight: bold;
    }
    span:last-child {
        text-align: right;
        width: 300px;
    }
}
</style>
