import { onMounted, onUnmounted, reactive } from 'vue'

interface IwinData {
    height: number
}
const useWindowResize = (): IwinData => {
    const winData = reactive({
        height: 0,
        width: 0
    })

    const handler = () => {
        winData.height = document.body.clientHeight
        winData.width = document.body.clientWidth
    }

    onMounted(() => {
        winData.height = document.body.clientHeight
        winData.width = document.body.clientWidth
        window.addEventListener('resize', handler)
    })
    onUnmounted(() => {
        window.removeEventListener('resize', handler)
    })
    return winData
}

export default useWindowResize
