<template>
    <Input
        ref="vinput"
        :style="{ width: `${width}px` }"
        v-model="input"
        allow-clear
        spellcheck="false"
        size="large"
        @clear="$emit('search', '')"
        @change=";(input = input.trim()), $emit('search', input)"
        :placeholder="placeholder">
        <template #prefix>
            <SvgIcon name="search" :size="18" color="#999" />
        </template>
    </Input>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
    name: 'Search',
    props: {
        placeholder: String,
        value: String,
        resetVal: Boolean,
        width: {
            type: Number,
            default: 200
        }
    },
    data() {
        return {
            input: this.value
        }
    },
    watch: {
        resetVal() {
            this.input = ''
        }
    }
})
</script>
<style lang="less" scoped>
.arco-input-wrapper {
    border: 1px solid #c2c2c2;
    border-radius: 4px;
    background-color: #fff;
}
</style>
