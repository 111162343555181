<template>
    <Table
        :class="['user-table', { 'hide-page-list': conf.total <= conf.pageSizeOptions[0] }]"
        :pagination="conf"
        :data="conf.tableData"
        :scroll="{ y: conf.height }"
        :bordered="false"
        :stripe="true">
        <template #columns>
            <TableColumn :title="t('user.team')" data-index="teamName" ellipsis>
                <template #cell="{ record }">
                    <span :title="record.teamName">{{ record.teamName }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('resource.teamOwner')" data-index="ownerEmail" ellipsis>
                <template #cell="{ record }">
                    <span :title="record.ownerEmail">{{ record.ownerEmail }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('resource.createTime')" data-index="addTime" align="center">
                <template #cell="{ record }">
                    <span>{{ moment(record.addTime).format('YYYY-MM-DD') }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('resource.expireTime')" data-index="expireTime" align="center">
                <template #cell="{ record }">
                    <span>{{ moment(record.expireTime).format('YYYY-MM-DD') }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('resource.spaceNum')" data-index="maxCount" align="center">
                <template #cell="{ record }">
                    <span>{{ record.maxCount }}</span>
                </template>
            </TableColumn>
            <TableColumn data-index="runningMaxCount" align="center">
                <template v-slot:title>
                    <span>
                        {{ t('resource.runningLimit') }}
                        <Tooltip :content="t('resource.runningSpace')">
                            <icon-info-circle style="color: #444; font-size: 22px; padding-top: 6px" />
                        </Tooltip>
                    </span>
                </template>
                <template #cell="{ record }">
                    <span>{{ record.runningMaxCount }}</span>
                </template>
            </TableColumn>
            <TableColumn :title="t('operation')" :width="110">
                <template #cell="{ record }">
                    <Space :size="10">
                        <SvgIcon name="set-up" :tip="t('config')" color="#2b32c9" hoverColor="#2bc954" :size="20" @click="$emit('modifyResource', record)" />
                        <SvgIcon name="delete" :tip="t('delete')" color="#2b32c9" hoverColor="#2bc954" :size="20" @click="deleteFunc(record)" />
                    </Space>
                </template>
            </TableColumn>
        </template>
    </Table>
</template>

<script>
import { defineComponent, inject } from 'vue'
import { Role } from '@/store'
import { useStore } from 'vuex'
export default defineComponent({
    props: {
        conf: {
            type: Object
        }
    },
    setup(props, ctx) {
        const t = inject('$t')
        const http = inject('$http')
        const moment = inject('$moment')
        const msg = inject('$message')
        const modal = inject('$modal')
        const store = useStore()
        const deleteFunc = (item) => {
            modal.warning({
                hideCancel: false,
                maskClosable: false,
                title: t('prompt'),
                okText: t('ok'),
                cancelText: t('cancel'),
                content: t('tip.deleteResourceTip', { name: item.teamName, resource: t('publicSrc') }),
                onOk: () => {
                    http.account.deleteResource(item.id).then(() => {
                        ctx.emit('deleteData')
                        msg.info(t('delSucc'))
                    })
                }
            })
        }
        return {
            deleteFunc,
            t,
            Role,
            store,
            moment
        }
    }
})
</script>

<style lang="less" scoped>
.user-table {
    .disable {
        color: #999;
    }
}
</style>
